import React from 'react';
import styled from 'styled-components';
import { IRegistrationFormFields } from '../models';
import Button from './shared/Button';
import Spacer from './shared/Spacer';
import InputField from './shared/InputField';
import { isEmailValid } from '../services/validations';
import { getProfession } from '../services/localStorage';

interface IRegistrationForm {
  handleChange: (
    name: string
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent) => void;
  values: IRegistrationFormFields;
  onProfessionClick: () => void;
  isLoading: boolean;
}

const StyledInputField = styled(InputField)`
  min-height: 25px;
  width: 25px;
`;

const InputName = styled(InputField)`
  width: 50%;
  display: block;
  margin 0 auto;
  letter-spacing: 16px;
  font-size: 1.5rem;
  text-transform: uppercase;
  text-align: center;
`;

const isFormValid = (values: IRegistrationFormFields) =>
  values.canContact === 'true' &&
  isEmailValid(values.email) &&
  values.location !== '' &&
  values.yearsOfExperience >= 0 &&
  values.nickname.length === 3 &&
  values.name !== '' &&
  values.degree !== '' &&
  values.password.length > 5;

const RegistrationForm: React.FC<IRegistrationForm> = ({
  handleSubmit,
  handleChange,
  values,
  onProfessionClick,
  isLoading
}) => (
  <form
    name="register-user"
    method="POST"
    data-netlify="true"
    onSubmit={handleSubmit}
  >
    <InputName
      label="Name (for highscore)"
      type="text"
      name="nickname"
      maxLength={3}
      onChange={handleChange('nickname')}
      value={values.nickname}
    />
    <Spacer spacing={2} />
    <InputField
      label="Name"
      type="name"
      name="name"
      onChange={handleChange('name')}
      value={values.name}
    />
    <Spacer spacing={2} />
    <InputField
      label="Email"
      type="email"
      name="email"
      onChange={handleChange('email')}
      value={values.email}
    />
    <Spacer spacing={2} />
    <InputField
      label="Password (Min. 6 characters)"
      type="password"
      name="password"
      onChange={handleChange('password')}
      value={values.password}
    />
    <Spacer spacing={2} />
    <InputField
      label="Field of expertise"
      type="button"
      onClick={onProfessionClick}
      name="fieldOfExpertise"
      value={getProfession()}
    />
    <Spacer spacing={2} />
    <InputField
      label="Years of experience"
      type="number"
      min={0}
      name="yearsOfExperience"
      onChange={handleChange('yearsOfExperience')}
      value={values.yearsOfExperience}
    />
    <Spacer spacing={2} />
    <InputField
      label="Degree"
      type="text"
      name="degree"
      onChange={handleChange('degree')}
      value={values.degree}
    />
    <Spacer spacing={2} />
    <InputField
      label="Present location"
      type="text"
      name="location"
      onChange={handleChange('location')}
      value={values.location}
    />
    <Spacer spacing={2} />
    <StyledInputField
      name="canContact"
      type="checkbox"
      label="Verify"
      labelCheckbox="I accept that Qamcom can save this information and contact me"
      onChange={handleChange('canContact')}
      value={values.canContact}
    />
    <Spacer spacing={2} />
    <Button
      primary
      loading={isLoading}
      type="submit"
      disabled={!isFormValid(values)}
    >
      Submit
    </Button>
  </form>
);

export default RegistrationForm;
