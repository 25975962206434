import React, { useContext, useEffect, useState } from 'react';
import { authenticate, submitForm } from '../api';
import { navigate } from 'gatsby';
import { ErrorContext } from '../contexts/ErrorContext';
import Container from '../components/shared/Container';
import { FirebaseUser, IRegistrationFormFields } from '../models';
import RegistrationForm from '../components/RegistrationForm';
import PageHeader from '../components/shared/PageHeader';
import {
  getFormData,
  getProductUserId,
  getProfession,
  removeFormData,
  saveFormData,
  saveHasPlayed,
  saveName,
  saveProductUserId
} from '../services/localStorage';
import styled from 'styled-components';
import firebase from 'gatsby-plugin-firebase';
import db from '../services/firebase';
import { pushRegisterEvent } from '../services/gtag';

const initialValues: IRegistrationFormFields = {
  email: '',
  nickname: '',
  name: '',
  password: '',
  canContact: 'false',
  fieldOfExpertise: '',
  yearsOfExperience: 1,
  location: '',
  degree: ''
};

const StyledContainer = styled(Container)`
  padding: 0 32px;
`;

const Register: React.FC = ({ location }) => {
  const { handleError } = useContext(ErrorContext);
  const [values, setValues] = useState<IRegistrationFormFields>(initialValues);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (location.state.fromGame) {
      saveHasPlayed();
    }
  }, []);

  useEffect(() => {
    const formData = getFormData();
    if (!!formData) {
      setValues({
        ...values,
        ...formData
      });
      removeFormData();
    }
  }, []);

  const handleChange = (name: string) => ({
    target
  }: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [name]: name === 'canContact' ? target.checked.toString() : target.value
    });
  };

  const registerUser = async (): Promise<string | undefined> => {
    try {
      const res = await firebase
        .auth()
        .createUserWithEmailAndPassword(values.email, values.password);
      return res.user.uid;
    } catch (error) {
      const { code, message } = error;
      if (code === 'auth/weak-password') {
        handleError('The password is too weak.');
      } else {
        handleError(message);
      }
      return;
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    const gwenId = getProductUserId();

    const valuesCopy = { ...values };
    delete valuesCopy.password;

    const user = {
      ...valuesCopy,
      fieldOfExpertise: getProfession(),
      nickname: values.nickname.toUpperCase(),
      gwenId
    } as FirebaseUser;

    const userId = await registerUser();

    if (!!userId) {
      db.saveUser(userId, user);
      saveName(user.nickname);
      saveProductUserId(gwenId);
      await authenticate(gwenId);

      const success = await submitForm('register-user', user);
      if (success) {
        pushRegisterEvent(user);
        navigate('/');
      } else {
        handleError('Could not create account');
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleProfessionClick = () => {
    const valuesCopy = { ...values };
    delete valuesCopy.password;
    saveFormData(valuesCopy);
    navigate('/profession', {
      state: {
        fromForm: true
      }
    });
  };

  const onBackButtonClick = () => {
    if (location.state.fromGame) {
      navigate('/end');
    } else {
      navigate(-1);
    }
  };

  return (
    <StyledContainer>
      <PageHeader onBackButtonClick={onBackButtonClick} title="Registration" />
      <RegistrationForm
        values={values}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        onProfessionClick={handleProfessionClick}
        isLoading={isLoading}
      />
    </StyledContainer>
  );
};

export default Register;
