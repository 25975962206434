import isBrowser from './isBrowser';
import { getProductUserId, getProfession } from './localStorage';
import firebase from 'gatsby-plugin-firebase';
import { FirebaseUser } from '../models';

export const pushRoomEvent = (roomId: string) => {
  if (isBrowser() && window.dataLayer) {
    const profession = getProfession();
    const user = firebase.auth().currentUser;
    window.dataLayer.push({
      event: 'enter_room',
      event_action: JSON.stringify({
        timeStamp: new Date().toDateString(),
        roomId,
        profession,
        userId: getProductUserId(),
        email: user?.email ?? ''
      })
    });
  }
};

export const pushRegisterEvent = (user: FirebaseUser) => {
  if (isBrowser() && window.dataLayer) {
    window.dataLayer.push({
      event: 'registration',
      event_action: JSON.stringify(user)
    });
  }
};
