import React from 'react';
import styled from 'styled-components';
import Spacer from './Spacer';

interface IInputField extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelCheckbox?: string;
}

const StyledInput = styled.input<IInputField>`
  box-sizing: border-box;
  &:out-of-range {
    border: 1px solid red;
  }
`;

const StyledLabel = styled.label`
  text-align: center;
  display: block;
`;
const LabelCheckbox = styled.label`
  font-size: 0.8rem;
  margin-left: 8px;
`;

const ContainerLabelCheckbox = styled.div`
  display: flex;
`;

const InputField: React.FC<IInputField> = ({
  label,
  labelCheckbox,
  ...props
}) => (
  <>
    {label && <StyledLabel htmlFor={props.name}>{`${label}`}</StyledLabel>}
    <Spacer spacing={0.5} />
    {labelCheckbox ? (
      <ContainerLabelCheckbox>
        <StyledInput {...props} />
        <LabelCheckbox htmlFor={props.name}>{`${labelCheckbox}`}</LabelCheckbox>
      </ContainerLabelCheckbox>
    ) : (
      <StyledInput {...props} />
    )}
  </>
);

export default InputField;
