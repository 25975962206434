import React from 'react';
import styled from 'styled-components';

const StyledDivider = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  height: 1.5px;
  width: 100%;
`;

const Divider = (props) => <StyledDivider {...props} />;

export default Divider;
