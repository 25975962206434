import { navigate } from 'gatsby-link';
import React from 'react';
import styled, { css } from 'styled-components';
import Divider from './Divider';
import Spacer from './Spacer';

interface ITitle {
  isSmall: boolean;
  color: string;
}

interface IPageHeader {
  title: string;
  hasBackButton?: boolean;
  iconRight?: string;
  isSmallTitle?: boolean;
  color?: string;
  onBackButtonClick?: () => void;
}

const Container = styled.div`
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
`;

const ContainerInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 50px;
`;

const ButtonBack = styled.div`
  font-size: 48px;
  height: 50px;
  width: 50px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -8px;
`;

const Title = styled.h1<ITitle>`
  margin: 0;
  padding: 0;
  left: 0;
  right: 0;
  position: absolute;
  text-align: center;
  font-size: 2rem;
  color: ${(props) => props.color};
  ${(props) =>
    props.isSmall &&
    css`
      font-size: 1.1rem;
    `}
`;

const PageHeader: React.FC<IPageHeader> = ({
  title,
  isSmallTitle = false,
  hasBackButton = true,
  iconRight,
  color = '#FFF',
  onBackButtonClick = undefined
}) => (
  <Container>
    <ContainerInner>
      {hasBackButton && (
        <ButtonBack
          onClick={() => {
            if (onBackButtonClick) {
              onBackButtonClick();
            } else {
              navigate(-1);
            }
          }}
        >
          &larr;
        </ButtonBack>
      )}
      <Title color={color} isSmall={isSmallTitle}>
        {title}
      </Title>
      {iconRight && (
        <img src={iconRight} height="70%" alt="header icon right" />
      )}
    </ContainerInner>
    <Spacer />
    <Divider />
  </Container>
);

export default PageHeader;
