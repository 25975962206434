import firebase from 'gatsby-plugin-firebase';
import { FirebaseUser } from '../models';

const saveUser = (uid: string, values: FirebaseUser) => {
  firebase.database().ref(`users/${uid}`).set(values);
};

export const getUser = async (
  uid: string
): Promise<FirebaseUser | undefined> => {
  const ref = firebase.database().ref();
  const snapshot = await ref.child('users').child(uid).get();
  if (snapshot.exists()) {
    return snapshot.val();
  } else {
    return;
  }
};

export const getAllUsers = async (): Promise<FirebaseUser[] | undefined> => {
  try {
    const ref = firebase.database().ref();
    const snapshot = await ref.child('users').get();
    if (snapshot.exists()) {
      return Object.values(snapshot.val());
    } else {
      return;
    }
  } catch (error) {
    console.log(error);
    return;
  }
};

export default { saveUser, getUser };
